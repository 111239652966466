import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

/*header*/

import logo from '../assets/images/logo.svg';
import logo1 from '../assets/images/logo-footer.svg';

import menu from '../assets/images/menu.svg';
import close from '../assets/images/close.svg';
import location_icon from '../assets/images/location.svg';
import phone_icon from '../assets/images/phone.svg';
import mail_icon from '../assets/images/mail.svg';

import enFlag from '../assets/images/en.png';
import srFlag from '../assets/images/sr.png';


import {
  Container,
  Row,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }




  render() {

    return (
      <header className={this.props[0].location.pathname !== '/' ? 'page-header' : ''}>
        <Container>
          <Row>
            <Col lg="2" xs="6" className="logo">
              <Link to='/'>
                <Isvg src={this.props[0].location.pathname !== '/' ? logo1 : logo} />
              </Link>
            </Col>
            <Col lg="10" xs="6" className="nav-container">
              <div className="top-nav">
                <div className="info">                                <ul>
                  <li><Isvg src={location_icon} /> Šabac, Savska 8, Srbija</li>
                  <li><Isvg src={phone_icon} /> +381 15 306 490 </li>
                  <li><Isvg src={mail_icon} /> info@dpsklas.rs</li>
                </ul>
                </div>
                <div className="langs">
                  <UncontrolledDropdown>
                    {this.props.lang == 'sr' ?
                      <DropdownToggle caret>
                        <img src={srFlag} /> <span>{'Srpski'.translate(this.props.lang)}</span>
                      </DropdownToggle>
                      :
                      <DropdownToggle caret>
                        <img src={enFlag} /> <span> {'Engleski'.translate(this.props.lang)}</span>
                      </DropdownToggle>
                    }
                    <DropdownMenu>
                      <DropdownItem onClick={() => this.props.setLang('sr')}> <img src={srFlag} /> {'Srpski'.translate(this.props.lang)}</DropdownItem>
                      <DropdownItem onClick={() => this.props.setLang('en')}><img src={enFlag} /> {'Engleski'.translate(this.props.lang)}</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                </div>
              </div>

              <ul className="navigation">
                <li><Link to='/'>{'POČETNA'.translate(this.props.lang)}</Link></li>
                <li className={this.props[0].location.pathname == '/stranica/o-nama' || this.props[0].location.pathname == '/stranica/dokumenta' ? 'active' : null}>
                  <UncontrolledDropdown>
                    <DropdownToggle caret>
                      {'O NAMA'.translate(this.props.lang)}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem><Link to='/stranica/o-nama'> {'VIZIJA I MISIJA'.translate(this.props.lang)}</Link></DropdownItem>
                      <DropdownItem><Link to='/stranica/dokumenta'>{'DOKUMENTA'.translate(this.props.lang)}</Link></DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>



                <li className={this.props[0].location.pathname == '/stranica/proizvodi' ? 'active' : null}><Link to='/stranica/proizvodi'>{'PROIZVODI'.translate(this.props.lang)}</Link></li>
                <li className={this.props[0].location.pathname == '/stranica/mreza-skladisnih-centara' || this.props[0].location.pathname == '/stranica/distributivni-centar-beograd' ? 'active' : null}>
                  <UncontrolledDropdown>
                    <DropdownToggle caret>
                      {'SKLADIŠTE I DISTRIBUCIJA'.translate(this.props.lang)}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem><Link to='/stranica/mreza-skladisnih-centara'> {'MREŽA SKLADIŠNIH CENTARA'.translate(this.props.lang)}</Link></DropdownItem>
                      <DropdownItem><Link to='/stranica/distributivni-centar-beograd'>{'DISTRIBUTIVNI CENTAR BEOGRAD'.translate(this.props.lang)}</Link></DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li className={this.props[0].location.pathname == '/stranica/sortna-semena-i-hibridi' || this.props[0].location.pathname == '/stranica/mineralna-djubriva' || this.props[0].location.pathname == '/stranica/pesticidi' || this.props[0].location.pathname == '/stranica/poljoprivredna-apoteka' ? 'active' : null}>
                  <UncontrolledDropdown>
                    <DropdownToggle caret>
                      {'SIROVINSKI SEKOTR'.translate(this.props.lang)}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem><Link to='/stranica/sortna-semena-i-hibridi'> {'SORTNA SEMENA I HIBRIDI'.translate(this.props.lang)}</Link></DropdownItem>
                      <DropdownItem><Link to='/stranica/mineralna-djubriva'>{'MINERALNA ĐUBRIVA'.translate(this.props.lang)}</Link></DropdownItem>
                      <DropdownItem><Link to='/stranica/pesticidi'>{'PESTICIDI'.translate(this.props.lang)}</Link></DropdownItem>
                      <DropdownItem><Link to='/stranica/poljoprivredna-apoteka'>{'POLJOPRIVREDNA APOTEKA'.translate(this.props.lang)}</Link></DropdownItem>

                    </DropdownMenu>
                  </UncontrolledDropdown>
                </li>
                <li className={this.props[0].location.pathname == '/novosti' ? 'active' : null}><Link to='/novosti'>{'OBAVEŠTENJA'.translate(this.props.lang)}</Link></li>

                <li className={this.props[0].location.pathname == '/zaposlenje' ? 'active' : null}><Link to='/zaposlenje'>{'ZAPOSLENJE'.translate(this.props.lang)}</Link></li>

                <li className={this.props[0].location.pathname == '/kontakt' ? 'active' : null}><Link to='/kontakt'>{'KONTAKT'.translate(this.props.lang)}</Link></li>
              </ul>
            </Col>
          </Row>
        </Container>

        <button className="hamburger" onClick={() => this.setState({ _menu: true })}>
          <Isvg src={menu} />
        </button>
        {this.state._menu ?
          <div className="navigation-mobile">
            <button className="hamburger" onClick={() => this.setState({ _menu: null })}>
              <Isvg src={close} />
            </button>

            <ul>
              <li className={this.props[0].location.pathname == '/' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/'>{'POČETNA'.translate(this.props.lang)}</Link></li>
              <li onClick={() => this.setState({ _showSubmenu1: true, _showSubmenu2: false, _showSubmenu3: false })}>{'O NAMA'.translate(this.props.lang)}
                {this.state._showSubmenu1 ?
                  <ul>
                    <li className={this.props[0].location.pathname == '/stranica/o-nama' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/o-nama'> {'VIZIJA I MISIJA'.translate(this.props.lang)}</Link></li>
                    <li className={this.props[0].location.pathname == '/stranica/dokumenta' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/dokumenta'>{'DOKUMENTA'.translate(this.props.lang)}</Link></li>
                  </ul>

                  :

                  null

                }


              </li>
              <li className={this.props[0].location.pathname == '/stranica/proizvodi' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/proizvodi'>{'PROIZVODI'.translate(this.props.lang)}</Link></li>
              <li onClick={() => this.setState({ _showSubmenu2: true, _showSubmenu1: false, _showSubmenu3: false })}>{'SKLADIŠTE I DISTRIBUCIJA'.translate(this.props.lang)}
                {this.state._showSubmenu2 ?

                  <ul>
                    <li className={this.props[0].location.pathname == '/stranica/mreza-skladisnih-centara' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/mreza-skladisnih-centara'> {'MREŽA SKLADIŠNIH CENTARA'.translate(this.props.lang)}</Link></li>
                    <li className={this.props[0].location.pathname == '/stranica/distributivni-centar-beograd' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/distributivni-centar-beograd'>{'DISTRIBUTIVNI CENTAR BEOGRAD'.translate(this.props.lang)}</Link></li>
                  </ul>

                  :
                  null
                }

              </li>
              <li onClick={() => this.setState({ _showSubmenu3: true, _showSubmenu1: false, _showSubmenu2: false })}>{'SIROVINSKI SEKTOR'.translate(this.props.lang)}
                {this.state._showSubmenu3 ?

                  <ul>
                    <li className={this.props[0].location.pathname == '/stranica/sortna-semena-i-hibridi' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/sortna-semena-i-hibridi'> {'SORTNA SEMENA I HIBRIDI'.translate(this.props.lang)}</Link></li>
                    <li className={this.props[0].location.pathname == '/stranica/mineralna-djubriva' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/mineralna-djubriva'>{'MINERALNA ĐUBRIVA'.translate(this.props.lang)}</Link></li>
                    <li className={this.props[0].location.pathname == '/stranica/pesticidi' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/pesticidi'>{'PESTICIDI'.translate(this.props.lang)}</Link></li>
                    <li className={this.props[0].location.pathname == '/stranica/poljoprivredna-apoteka' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/stranica/poljoprivredna-apoteka'>{'POLJOPRIVREDNA APOTEKA'.translate(this.props.lang)}</Link></li>
                  </ul>

                  :
                  null

                }


              </li>

              <li className={this.props[0].location.pathname == '/novosti' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/novosti'>{'OBAVEŠTENJA'.translate(this.props.lang)}</Link></li>
              <li className={this.props[0].location.pathname == '/zaposlenje' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/zaposlenje'>{'ZAPOSLENJE'.translate(this.props.lang)}</Link></li>
              <li className={this.props[0].location.pathname == '/kontakt' ? 'active' : null} onClick={() => this.setState({ _menu: null })}><Link to='/kontakt'>{'KONTAKT'.translate(this.props.lang)}</Link></li>
            </ul>
          </div>
          :
          null
        }

      </header>
    );
  }
}

export default Header;