import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import cert1 from '../assets/images/cert1.png';
import cert2 from '../assets/images/cert2.png';
import cert3 from '../assets/images/cert3.png';

import intobg from '../assets/images/bg2.png';
import banner1 from '../assets/images/bg2.png';


import location_icon from '../assets/images/location.svg';
import phone_icon from '../assets/images/phone.svg';
import mail_icon from '../assets/images/mail.svg';
import fb_icon from '../assets/images/facebook.svg';
import instagram_icon from '../assets/images/instagram.svg';
import youtube_icon from '../assets/images/youtube.svg';

import edit_icon from '../assets/images/edit.png';
import delete_icon from '../assets/images/trash.png';
import upload_icon from '../assets/svg/upload.svg';
import monte from '../assets/images/MonteKlasLogobijela.png';


import Map from '../components/map'
import {
    Container,
    Row,
    Col,

} from 'reactstrap';

import ContactForm from '../components/forms/contactForm';
import { throws } from 'assert';

class EditPage extends Component {
    constructor(props) {
        super(props);
        this.selectElement = this.selectElement.bind(this);
        this.editElement = this.editElement.bind(this);
        this.save = this.save.bind(this);


        this.curr = null;

        this.state = {
            currentElement: null,
            //_activeElement: '0',
            elements: [
                {
                    tag: 'div',
                    attributes: {},
                    children: [],
                    path: '0',
                    disableNewElements: false,
                }
            ],
            _activeElement: null,
            content: {
                sr: '<div></div>',
                en: '<div></div>',

            },
            toolboxPosition: {
                left: -200,
                right: -200
            }
        };

        this._refs = {};
    }


    componentDidMount() {
        fetch('https://dpsklas.novamedia.agency/admin/fetchOne/pages/' + this.props[0].match.params.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.props[0].match.params.token}`

            }
        }).then((res) => res.json()).then((result) => {
            //this.updateInnerHtml(result.elements);
            console.log(result);
            this.setState(result)
            if (result.content)
                this.state.html.innerHTML = result.content && result.content[this.props[0].match.params.lang] ? result.content[this.props[0].match.params.lang] : result.content['sr'] ? result.content['sr'] : '<div></div>';

        })
    }


    save() {
        let content = this.state.content;
        content[this.props[0].match.params.lang] = this.state.html.innerHTML.replace(/contenteditable="true"/g, '');
        fetch('https://dpsklas.novamedia.agency/admin/updateOne/pages/' + this.props[0].match.params.id, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.props[0].match.params.token}`

            },
            body: JSON.stringify({
                content: content
            })
        }).then((res) => res.json()).then((result) => {
        })



    }

    selectElement(name, tag, attributes, disableNewElements, allowInnerHTML) {

        this.addElement(tag, attributes, allowInnerHTML)
    }

    addElement(tag, attributes, allowInnerHTML) {
        let node = window.document.createElement(tag);
        for (var key in attributes) {
            if (attributes.hasOwnProperty(key)) {
                node.setAttribute(key, attributes[key]);
            }
        }

        if (tag == 'ul') {
            let liNode = window.document.createElement('li');
            node.appendChild(liNode);
        }

        this.state._activeElement.appendChild(node);
    }


    editElement() {
        let el = this.state._editElement;
        let node = this.state._activeElement;

        for (let i = 0; i < el.attributes.length; i++) {
            if (!el.attributes[i].value) {
                node.removeAttribute(el.attributes[i].name);
            } else {
                node.setAttribute(el.attributes[i].name, el.attributes[i].value)
            }
        }

        this.setState({
            _editElement: null
        })
    }


    render() {
        return (
            <div className="page-wrap">
                <div className="page-into">
                    <img src={intobg} />
                    <div className="overlay"></div>
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>{this.state.name && this.state.name[this.props[0].match.params.lang]}</h1>

                            </Col>
                            <Col lg="6">
                                <ul className="breadcrumb">
                                    <li><Link to="/">Početna</Link></li>
                                    <li><Link to="/">{this.state.name && this.state.name[this.props[0].match.params.lang]}</Link></li>

                                </ul>
                            </Col>
                        </Row>
                    </Container>


                </div>

                <div className="toolbox">
                    <div className="tabs">
                        <button onClick={() => this.selectElement('Container', 'div', { class: 'container' }, false, false)}>Container</button>
                        <button onClick={() => this.selectElement('Row', 'div', { class: 'row' }, false, false)}>Row</button>
                        <button onClick={() => this.selectElement('Col', 'div', { class: 'col-lg-6 col-sm-6 col-6', style: '' }, false, false)}>Col</button>
                        <button onClick={() => this.selectElement('Heading', 'h1', {}, true, true)}>Heading 1</button>
                        <button onClick={() => this.selectElement('Heading', 'h2', {}, true, true)}>Heading 2</button>
                        <button onClick={() => this.selectElement('Heading', 'h3', {}, true, true)}>Heading 3</button>
                        <button onClick={() => this.selectElement('Heading', 'h4', {}, true, true)}>Heading 4</button>
                        <button onClick={() => this.selectElement('Heading', 'h5', {}, true, true)}>Heading 5</button>
                        <button onClick={() => this.selectElement('Heading', 'h6', {}, true, true)}>Heading 6</button>
                        <button onClick={() => this.selectElement('Parahraph', 'p', {}, true, true)}>Paragraph</button>
                        <button onClick={() => this.selectElement('List', 'ul', {}, true, true)}>Enumeration</button>
                        <button onClick={() => this.selectElement('Image', 'img', {}, true)}>Image</button>
                        <button onClick={() => this.selectElement('Button', 'button', {}, true)}>Button</button>
                        <button onClick={() => this.selectElement('Link', 'a', {}, true)}>Link</button>




                        <button onClick={() => this.save()}>Save</button>

                    </div>

                </div>

                <section className="section work-section" ref={(node) => {
                    console.log("update Ref", node)
                    if (!this.state._activeElement) {
                        this.setState({
                            _activeElement: node && node.childNodes[0],
                            html: node
                        });
                        if (node)
                            node.innerHTML = this.state.content && this.state.content[this.props[0].match.params.lang];
                    }
                }} onClick={(e) => {
                    e.target.setAttribute('__active', true);
                    e.target.setAttribute('contenteditable', true);
                    console.log(this.state._activeElement);
                    if (this.state._activeElement) {
                        this.state._activeElement.setAttribute('__active', false);
                    }
                    let rect = e.target.getBoundingClientRect();
                    this.setState({
                        _activeElement: e.target,
                        toolboxPosition: {
                            left: rect.left + rect.width - 80,
                            top: rect.top + 10
                        }

                    })
                }} >
                </section>
                {this.state._editElement != null ?
                    <div className="props-modal">
                        <h6>Attributes </h6>

                        <ul>
                            {
                                this.state._editElement.attributes.map((item, idx) => {
                                    return (
                                        <li>
                                            <input type="text" value={item.name} onChange={(e) => {
                                                let el = this.state._editElement;
                                                el.attributes[idx].name = e.target.value
                                                this.setState({
                                                    _editElement: el
                                                })

                                            }} />
                                            <input type="text" value={item.value} onChange={(e) => {
                                                let el = this.state._editElement;
                                                el.attributes[idx].value = e.target.value
                                                this.setState({
                                                    _editElement: el
                                                })

                                            }} />

                                            {
                                                item.name == 'src' || item.name == 'href' ?
                                                    <div className="file-upload">
                                                        <input type="file" onChange={(e) => {
                                                            let input = e.target;
                                                            if (input.files && input.files[0]) {
                                                                var reader = new FileReader();

                                                                reader.onload = async (e) => {
                                                                    this.setState({
                                                                        _loading: true
                                                                    })
                                                                    fetch('https://dpsklas.novamedia.agency/admin/upload', {
                                                                        method: 'POST',
                                                                        headers: {
                                                                            Accept: 'application/json',
                                                                            'Content-Type': 'application/json',
                                                                            'Authorization': `Bearer ${this.props[0].match.params.token}`

                                                                        },
                                                                        body: JSON.stringify({ image: e.target.result })
                                                                    }).then((res) => res.text()).then((img) => {
                                                                        let el = this.state._editElement;
                                                                        el.attributes[idx].value = img
                                                                        this.setState({
                                                                            _editElement: el
                                                                        })
                        
                                                                    });

                                                                }

                                                                reader.readAsDataURL(input.files[0]);
                                                            }

                                                        }} />
                                                        <button>
                                                            <Isvg src={upload_icon} />
                                                        </button>
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </li>

                                    )
                                })
                            }
                        </ul>

                        <div className="buttons">
                            <button onClick={() => this.editElement()}>Close</button>
                            <button onClick={() => {
                                let el = this.state._editElement;
                                el.attributes.push({ name: '', value: '' });
                                this.setState({
                                    _editElement: el
                                })
                            }}>New attribute</button>
                        </div>
                    </div>

                    : null
                }

                <div className="__element__toolbox" style={{ position: 'fixed', top: this.state.toolboxPosition.top, left: this.state.toolboxPosition.left }}>
                    <button onClick={() => {
                        let node = this.state._activeElement;
                        let attributes = [];

                        if (node.attributes) {
                            for (let i = 0; i < node.attributes.length; i++) {
                                //console.log(node.attributes[i].nodeName, node.attributes[i].nodeValue);
                                attributes.push({ name: node.attributes[i].nodeName, value: node.attributes[i].nodeValue });
                            }
                        }
                        this.setState({
                            _editElement: {
                                attributes: attributes
                            }
                        })
                    }}><img src={edit_icon} /></button>
                    <button onClick={() => {
                        this.state._activeElement.remove();
                        this.setState({
                            toolboxPosition: {
                                left: -200,
                                top: -200
                            }
                        })
                    }}><img src={delete_icon} /></button>
                </div>



                <Container className="certificates">
                    <Row>
                        <Col lg="6">
                            <h6>{'FIRMA OD POVERENJA'.translate(this.props.lang)}</h6>
                            <p>{'Sertifikati ISOQAR, UKAS i Firma od poverenja'.translate(this.props.lang)}</p>
                        </Col>
                        <Col lg="6" className="items">
                            <img src={cert1} />
                            <img src={cert2} />
                            <img src={cert3} />

                        </Col>
                    </Row>

                </Container>


                <section className="contact-section">
                    <Container>

                        <Row>
                            <Col lg="6">
                                <h3>{'Kontakt'.translate(this.props.lang)}</h3>
                                <h6>DPS KLAS GROUP D.O.O</h6>
                                <ul>
                                    <li><Isvg src={location_icon} /> Šabac, Savska 8, Srbija</li>
                                    <li><Isvg src={phone_icon} /> +381 15 306 490 </li>
                                    <li><Isvg src={mail_icon} /> info@dpsklas.rs</li>
                                </ul>

                                <p>{'Pratite nas:'.translate(this.props.lang)}</p>
                                <ul className="social">
                                <li><a href="https://www.facebook.com/dpsklas/" target="_blank"><Isvg src={fb_icon} /></a></li>
                                    <li><a href="https://www.instagram.com/dps.klas.group/?hl=sr" target="_blank"><Isvg src={instagram_icon} /></a></li>
                                    <li><a href="https://www.youtube.com/channel/UC_gQ72KvKolpc6BgMguiEdA" target="_blank"><Isvg src={youtube_icon} /></a></li>

                                </ul>
                                <a href="http://www.monteklas.me/" target="_blank" className="monte-klas">
                                <img src={monte} />
                                </a>

                            </Col>
                            <Col lg="6" className="right-col">
                                <h3>{'Kontaktirajte nas'.translate(this.props.lang)}</h3>
                                <p>{'Budite slobodni da nas kontaktirate.'.translate(this.props.lang)}<br />{'Odgovorićemo Vam u što kraćem roku.'.translate(this.props.lang)}</p>
                                <ContactForm lang={this.props.lang} />

                            </Col>
                        </Row>
                    </Container>
                </section>
                <Map {...this.props} />

            </div>
        );
    }
}

export default Page(EditPage);