import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import cert1 from '../assets/images/cert1.png';
import cert2 from '../assets/images/cert2.png';
import cert3 from '../assets/images/cert3.png';

import intobg from '../assets/images/bg2.png';
import banner1 from '../assets/images/bg2.png';


import location_icon from '../assets/images/location.svg';
import phone_icon from '../assets/images/phone.svg';
import mail_icon from '../assets/images/mail.svg';
import fb_icon from '../assets/images/facebook.svg';
import instagram_icon from '../assets/images/instagram.svg';
import youtube_icon from '../assets/images/youtube.svg';
import monte from '../assets/images/MonteKlasLogobijela.png';

import Map from '../components/map'
import {
    Container,
    Row,
    Col,

} from 'reactstrap';

import moment from 'moment';
import ContactForm from '../components/forms/contactForm';

import DocumentMeta from 'react-document-meta';
var striptags = require('striptags');


class NewsDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lastNews: []
        };
    }

    componentDidMount() {
        fetch('https://dpsklas.novamedia.agency/news/' + this.props[0].match.params.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

            }
        }).then((res) => res.json()).then((result) => {
            this.setState(result)

        })

        fetch('https://dpsklas.novamedia.agency/lastNews/' + this.props[0].match.params.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

            }
        }).then((res) => res.json()).then((result) => {
            this.setState({ lastNews: result })

        })

        window.scrollTo(0, 0);


    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            fetch('https://dpsklas.novamedia.agency/news/' + this.props[0].match.params.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',

                }
            }).then((res) => res.json()).then((result) => {
                this.setState(result)

            })

            fetch('https://dpsklas.novamedia.agency/lastNews/' + this.props[0].match.params.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',

                }
            }).then((res) => res.json()).then((result) => {
                this.setState({ lastNews: result })

            })
            window.scrollTo(0, 0);


        }
    }


    render() {



        const meta = {
            title: (this.state.name && this.state.name[this.props.lang]) +' | „DPS KLAS GROUP“ d.o.o',
            meta: {
                charset: 'utf-8',
                name: {
                    'og:url': `https://dpsklas.rs/novosti/${this.state._id}`,
                    'og:title': this.state.name && this.state.name[this.props.lang],
                    'og:image': this.state.image,
                    'og:description': this.state.shortDescription && this.state.shortDescription[this.props.lang]
                }
            }
        };


        return (
            <div className="page-wrap">
                <DocumentMeta {...meta} />
                <div className="page-into">
                    <img src={intobg} />
                    <div className="overlay"></div>
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>{'NOVOSTI'.translate(this.props.lang)}</h1>

                            </Col>
                            <Col lg="6">
                                <ul className="breadcrumb">
                                    <li><Link to="/">{'Početna'.translate(this.props.lang)}</Link></li>
                                    <li><Link to="/">{'Novosti'.translate(this.props.lang)}</Link></li>

                                </ul>
                            </Col>
                        </Row>
                    </Container>


                </div>
                <Container >
                    <Row>
                        <Col lg="9" className="news-detail">
                            <h2>{this.state.name && this.state.name[this.props.lang]}</h2>
                            <p className="date">{moment.unix(this.state.timestamp).format('DD. MMMM YYYY.')}</p>
                            <p>{this.state.shortDescription && this.state.shortDescription[this.props.lang]}</p>
                            <img src={this.state.image} />
                            <div dangerouslySetInnerHTML={{ __html: this.state.content  && this.state.content[this.props.lang] }}></div>


                        </Col>
                        <Col lg="3" className="side-news">
                            <h3>{'Najnovije novosti'.translate(this.props.lang)}</h3>
                            {
                                this.state.lastNews.map((item, idx) => {
                                    return (
                                        <Link to={`/novosti/${item._id}`}>
                                                <article>
                                                    <img src={item.image} />
                                                    <h6>{item.name && item.name[this.props.lang]}</h6>
                                                    <p className="date">{moment.unix(item.timestamp).format('DD. MMMM YYYY.')}</p>
                                                    <p>{item.shortDescription && item.shortDescription[this.props.lang] && item.shortDescription[this.props.lang].length > 81 ? item.shortDescription[this.props.lang].substr(0, 81) + '...' : item.shortDescription && item.shortDescription[this.props.lang]}</p>
                                                    <button>{'DETALJNIJE'.translate(this.props.lang)}</button>
                                                </article>
                                        </Link>

                                    )
                                })
                            }

                        </Col>
                    </Row>
                </Container>


                <Container className="certificates">
                    <Row>
                        <Col lg="6">
                            <h6>{'FIRMA OD POVERENJA'.translate(this.props.lang)}</h6>
                            <p>{'Sertifikati ISOQAR, UKAS i Firma od poverenja'.translate(this.props.lang)}</p>
                        </Col>
                        <Col lg="6" className="items">
                            <img src={cert1} />
                            <img src={cert2} />
                            <img src={cert3} />

                        </Col>
                    </Row>

                </Container>


                <section className="contact-section">
                    <Container>

                        <Row>
                            <Col lg="6">
                                <h3>{'Kontakt'.translate(this.props.lang)}</h3>
                                <h6>{'DPS KLAS GROUP D.O.O'.translate(this.props.lang)}</h6>
                                <ul>
                                    <li><Isvg src={location_icon} /> Šabac, Savska 8, Srbija</li>
                                    <li><Isvg src={phone_icon} /> +381 15 306 490 </li>
                                    <li><Isvg src={mail_icon} /> info@dpsklas.rs</li>
                                </ul>

                                <p>Pratite nas:</p>
                                <ul className="social">
                                <li><a href="https://www.facebook.com/dpsklas/" target="_blank"><Isvg src={fb_icon} /></a></li>
                                    <li><a href="https://www.instagram.com/dps.klas.group/?hl=sr" target="_blank"><Isvg src={instagram_icon} /></a></li>
                                    <li><a href="https://www.youtube.com/channel/UC_gQ72KvKolpc6BgMguiEdA" target="_blank"><Isvg src={youtube_icon} /></a></li>

                                </ul>
                                <a href="http://www.monteklas.me/" target="_blank" className="monte-klas">
                                <img src={monte} />
                                </a>

                            </Col>
                            <Col lg="6" className="right-col">
                                <h3>{'Kontaktirajte nas'.translate(this.props.lang)}</h3>
                                <p>{'Budite slobodni da nas kontaktirate.'.translate(this.props.lang)}<br />{'Odgovorićemo Vam u što kraćem roku.'.translate(this.props.lang)}</p>
                                <ContactForm lang={this.props.lang} />

                            </Col>
                        </Row>
                    </Container>
                </section>
                <Map {...this.props} />

            </div>
        );
    }
}

export default Page(NewsDetailPage);