import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'

import Text from './fields/text';
import Textarea from './fields/textarea';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )

const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )


class form extends React.Component {

    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
        this.state = {}
    }

    submit(data){
        console.log(data);

        
        fetch('https://dpsklas.novamedia.agency/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify(
                data
            )
        }).then((res) => res.json()).then((result) => {
            this.setState({
                _done: true
            })
        })


    }


    render() {

        const { handleSubmit, pristine, reset, submitting } = this.props;
        console.log(pristine, submitting);

        return (
            <form onSubmit={handleSubmit(this.submit)}>
                <Field
                    name="name"
                    component={renderTextField}
                    placeholder={"Vaše ime".translate(this.props.lang)}

                ></Field>

                <Field
                    name="email"
                    component={renderTextField}
                    placeholder={"E-mail adresa".translate(this.props.lang)}

                ></Field>
                <Field
                    name="message"
                    component={renderTextareaField}
                    placeholder={"Komentar".translate(this.props.lang)}

                ></Field>
                { this.state._done ?
                <p>{'Vaša poruka je uspešno poslata, očekujte odgovor ubrzo'.translate(this.props.lang)}</p>
                :
                <button className="form-button">{'POŠALJI PORUKU'.translate(this.props.lang)}</button>
                }
            </form>
        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
