import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import cert1 from '../assets/images/cert1.png';
import cert2 from '../assets/images/cert2.png';
import cert3 from '../assets/images/cert3.png';

import intobg from '../assets/images/bg2.png';
import banner1 from '../assets/images/bg2.png';


import location_icon from '../assets/images/location.svg';
import phone_icon from '../assets/images/phone.svg';
import mail_icon from '../assets/images/mail.svg';
import fb_icon from '../assets/images/facebook.svg';
import instagram_icon from '../assets/images/instagram.svg';
import youtube_icon from '../assets/images/youtube.svg';

import edit_icon from '../assets/images/edit.png';
import delete_icon from '../assets/images/trash.png';
import monte from '../assets/images/MonteKlasLogobijela.png';

import Map from '../components/map'
import {
    Container,
    Row,
    Col,

} from 'reactstrap';


import ContactForm from '../components/forms/contactForm';

class PageTemplate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            content: ''
        };
    }

    componentDidMount() {
        fetch('https://dpsklas.novamedia.agency/pages/' + this.props[0].match.params.alias, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',

            }
        }).then((res) => res.json()).then((result) => {
            this.setState(result)
            window.scrollTo(0, 0);


        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {
            fetch('https://dpsklas.novamedia.agency/pages/' + this.props[0].match.params.alias, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',

                }
            }).then((res) => res.json()).then((result) => {
                this.setState(result)
                window.scrollTo(0, 0);


            })

        }
    }

    render() {
        return (
            <div className="page-wrap">
                <div className="page-into">
                    <img src={intobg} />
                    <div className="overlay"></div>
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>{this.state.name && this.state.name[this.props.lang]}</h1>

                            </Col>
                            <Col lg="6">
                                <ul className="breadcrumb">
                                    <li><Link to="/">Početna</Link></li>
                                    <li><Link to="/">{this.state.name && this.state.name[this.props.lang]}</Link></li>

                                </ul>
                            </Col>
                        </Row>
                    </Container>


                </div>



                <div className="section" dangerouslySetInnerHTML={{ __html: this.state.content && this.state.content[this.props.lang] }}>

                </div>

                {/*
                <section className="section our-mission">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h2 className="title">Misija i vizija</h2>
                            </Col>
                            <Col lg="6">
                                <p><strong>DRUŠTVENA ODGOVORNOST KOMPANIJE</strong><br />
                                    Kao društveno  odgovorna kompanija, stalnim angažovanjem učestvujemo u razvoju zajednice.</p>
                                <p>Donatori smo u:</p>
                                <ul>
                                    <li>sportu</li>
                                    <li>brazovanju</li>
                                    <li>kulturnim i verskim institucijama</li>
                                    <li>zdravstvu</li>

                                </ul>

                                <p>Kao ekološki odgovorna kompanija, ispunjavamo sve zahteve za očuvanje životne sredine.</p>


                            </Col>
                            <Col lg="6">
                                <p><strong>ŽELIMO DA:</strong></p>
                                <ul>
                                    <li>Zadržimo i uvećamo lidersku poziciju u oblasti mlinarstva i skladištenja žitarica</li>
                                    <li>Postavimo visoke standarde u oblasti agrobiznisa, jačanjem u svim segmentima trgovinom žitarica</li>
                                    <li>Automatizujemo proizvodnju</li>
                                    <li>Razvijemo stabilnu logističku mrežu</li>
                                    <li>Motivišemo zaposlene da postižu maksimalne rezultate</li>
                                    <li>Razvijemo odgovornost prema životnoj sredini i društvenojzajednici</li>
                                    <li>Unapređujemo korektne partnerske odnose sa kupcima, dobavljačima i ostalim poslovnim partnerima</li>

                                </ul>
                            </Col>
                        </Row>

                    </Container>
                </section>
                

                <section className="section section-banner">
                    <img src={banner1} />
                    <Container>
                        <Row>
                            <Col lg="12">
                                <h6 className="title-with-icon">Osnov svakog dobro obavljenog posla je poverenje,<br /> zato mi ulažemo maksimalan trud:</h6>
                                <h4>Da svaka njiva donese bogat prinos,<br /> svako zrno bude na sigurnom<br /> i iz njega dobijete najkvalitetnije proizvode.</h4>
                            </Col>
                        </Row>
                    </Container>
                </section>
*/}
                <Container className="certificates">
                    <Row>
                        <Col lg="6">
                            <h6>{'FIRMA OD POVERENJA'.translate(this.props.lang)}</h6>
                            <p>{'Sertifikati ISOQAR, UKAS i Firma od poverenja'.translate(this.props.lang)}</p>
                        </Col>
                        <Col lg="6" className="items">
                            <img src={cert1} />
                            <img src={cert2} />
                            <img src={cert3} />

                        </Col>
                    </Row>

                </Container>


                <section className="contact-section">
                    <Container>

                        <Row>
                            <Col lg="6">
                            <h3>{'Kontakt'.translate(this.props.lang)}</h3>
                                <h6>DPS KLAS GROUP D.O.O</h6>
                                <ul>
                                    <li><Isvg src={location_icon} /> Šabac, Savska 8, Srbija</li>
                                    <li><Isvg src={phone_icon} /> +381 15 306 490 </li>
                                    <li><Isvg src={mail_icon} /> info@dpsklas.rs</li>
                                </ul>

                                <p>{'Pratite nas:'.translate(this.props.lang)}</p>
                                <ul className="social">
                                    <li><a href="https://www.facebook.com/dpsklas/" target="_blank"><Isvg src={fb_icon} /></a></li>
                                    <li><a href="https://www.instagram.com/dps.klas.group/?hl=sr" target="_blank"><Isvg src={instagram_icon} /></a></li>
                                    <li><a href="https://www.youtube.com/channel/UC_gQ72KvKolpc6BgMguiEdA" target="_blank"><Isvg src={youtube_icon} /></a></li>

                                </ul>

                                <a href="http://www.monteklas.me/" target="_blank" className="monte-klas">
                                <img src={monte} />
                                </a>
                            </Col>
                            <Col lg="6" className="right-col">
                            <h3>{'Kontaktirajte nas'.translate(this.props.lang)}</h3>
                                <p>{'Budite slobodni da nas kontaktirate.'.translate(this.props.lang)}<br />{'Odgovorićemo Vam u što kraćem roku.'.translate(this.props.lang)}</p>
                                <ContactForm lang={this.props.lang} />

                            </Col>
                        </Row>
                    </Container>
                </section>
                <Map {...this.props} />

            </div>
        );
    }
}

export default Page(PageTemplate);