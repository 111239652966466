import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import cert1 from '../assets/images/cert1.png';
import cert2 from '../assets/images/cert2.png';
import cert3 from '../assets/images/cert3.png';

import intobg from '../assets/images/bg2.png';
import banner1 from '../assets/images/bg2.png';


import location_icon from '../assets/images/location.svg';
import phone_icon from '../assets/images/phone.svg';
import mail_icon from '../assets/images/mail.svg';
import fb_icon from '../assets/images/facebook.svg';
import instagram_icon from '../assets/images/instagram.svg';
import youtube_icon from '../assets/images/youtube.svg';
import monte from '../assets/images/monteklas1.png';

import Map from '../components/map'
import {
    Container,
    Row,
    Col,

} from 'reactstrap';


import ContactForm from '../components/forms/contactForm';

class ContactPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }


    render() {
        return (
            <div className="page-wrap">
                <div className="page-into">
                    <img src={intobg} />
                    <div className="overlay"></div>
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>{'KONTAKT'.translate(this.props.lang)}</h1>

                            </Col>
                            <Col lg="6">
                                <ul className="breadcrumb">
                                    <li><Link to="/">{'Početna'.translate(this.props.lang)}</Link></li>
                                    <li><Link to="/">{'Kontakt'.translate(this.props.lang)}</Link></li>

                                </ul>
                            </Col>
                        </Row>
                    </Container>


                </div>




                <section className="contact-page-section">
                    <Container>

                        <Row>
                            <Col lg="6">
                                <h3>{'Kontaktirajte nas'.translate(this.props.lang)}</h3>
                                <p>{'Budite slobodni da nas kontaktirate.'.translate(this.props.lang)}<br />{'Odgovorićemo Vam u što kraćem roku.'.translate(this.props.lang)}</p>
                                <ContactForm lang={this.props.lang} />
                            </Col>
                            <Col lg="6" className="right-col">


                                <h3>{'Kontakt'.translate(this.props.lang)}</h3>
                                <h6>DPS KLAS GROUP D.O.O</h6>
                                <ul>
                                    <li><Isvg src={location_icon} /> Šabac, Savska 8, Srbija</li>
                                    <li><Isvg src={phone_icon} /> +381 15 306 490 </li>
                                    <li><Isvg src={mail_icon} /> info@dpsklas.rs</li>
                                </ul>

                                <p>{'Pratite nas:'.translate(this.props.lang)}</p>
                                <ul className="social">
                                <li><a href="https://www.facebook.com/dpsklas/" target="_blank"><Isvg src={fb_icon} /></a></li>
                                    <li><a href="https://www.instagram.com/dps.klas.group/?hl=sr" target="_blank"><Isvg src={instagram_icon} /></a></li>
                                    <li><a href="https://www.youtube.com/channel/UC_gQ72KvKolpc6BgMguiEdA" target="_blank"><Isvg src={youtube_icon} /></a></li>

                                </ul>
                                <a href="http://www.monteklas.me/" target="_blank" className="monte-klas">
                                <img src={monte} />
                                </a>

                            </Col>
                        </Row>
                        <Row className="contact-locations">
                            <Col lg="3" xs="6">
                                <h6>S.C. POPOVIĆ ŠABAC</h6>
                                <p>Savska 8</p>
                                <p>064/8500144</p>

                            </Col>

                            <Col lg="3" xs="6">
                                <h6>S.C. POPOVIĆ BOGATIĆ</h6>
                                <p>Dubljanski put bb</p>
                                <p>064/8500116</p>

                            </Col>

                            <Col lg="3" xs="6">
                                <h6>S.C. POPOVIĆ KLENJE</h6>
                                <p>Popa Cvetina bb</p>
                                <p> 065/ 4577679 </p>

                            </Col>

                            <Col lg="3" xs="6">
                                <h6>S.C. POPOVIĆ  DRENOVAC</h6>
                                <p>Kapetanova 60</p>
                                <p>064/8500149 </p>

                            </Col>

                            <Col lg="3" xs="6">
                                <h6>S.C. POPOVIĆ BADOVINCI</h6>
                                <p>Karađorđeva 29/33</p>
                                <p>064/8500136</p>

                            </Col>

                            <Col lg="3" xs="6">
                                <h6>S.C. POPOVIĆ  DEBRC</h6>
                                <p>Debrc bb</p>
                                <p>064/8500172</p>

                            </Col>

                            <Col lg="3" xs="6">
                                <h6>S.C. POPOVIĆ PLATIČEVO</h6>
                                <p> Železnička 8</p>
                                <p> 065/4577680</p>

                            </Col>

                            <Col lg="3" xs="6">
                                <h6>SS.C. POPOVIĆ JARAK</h6>
                                <p>Grobljanska bb</p>
                                <p> 065/4577674</p>

                            </Col>

                            <Col lg="3" xs="6">
                                <h6>S.C. POPOVIĆ NIKINCI</h6>
                                <p>Železnička bb </p>
                                <p>064/8500128</p>

                            </Col>

                            <Col lg="3" xs="6">
                                <h6>S.C. POPOVIĆ  VOGANJ</h6>
                                <p>Rumska 70 </p>
                                <p>064/8500133</p>

                            </Col>

                            <Col lg="3" xs="6">
                                <h6>S.C. POPOVIĆ  BUĐANOVCI</h6>
                                <p>Ive Lole Ribara 52</p>
                                <p>064/8500127</p>

                            </Col>

                            <Col lg="3" xs="6">
                                <h6>Distributivni centar Beograd</h6>
                                <p>Milutina Milankovića 29A</p>
                                <p>065/45 77 678</p>

                            </Col>

                        </Row>
                    </Container>
                </section>
                <Map {...this.props} />

            </div>
        );
    }
}

export default Page(ContactPage);