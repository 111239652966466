export const langs = { 
    "en":{ 
        "VIZIJA I MISIJA": "OUR MISSION",
       "FIRMA OD POVERENJA":"COMPANY OF TRUST",
       "Sertifikati ISOQAR, UKAS i Firma od poverenja":"Certificates from ISOQAR, UKAS and Firm of Trust",
       "Srpski":"Serbian",
       "Engleski":"English",
       "POČETNA":"HOME",
       "O NAMA":"ABOUT US",
       "PROIZVODI":"PRODUCTS",
       "SKLADIŠTE I DISTRIBUCIJA":"WAREHOUSE AND DISTRIBUTION",
       "MREŽA SKLADIŠNIH CENTARA":"WAREHOUSE CENTERS NETWORK",
       "DISTRIBUTIVNI CENTAR BEOGRAD":"DISTRIBUTION CENTER BELGRADE",
       "OBAVEŠTENJA":"NEWS",
       "ZAPOSLENJE":"EMPLOYMENT",
       "SIROVINSKI SEKOTR":"RAW MATERIAL SECTOR",
       "SORTNA SEMENA I HIBRIDI":"VARIETY SEEDS AND HYBRIDS",
       "MINERALNA ĐUBRIVA":"MINERAL FERTILIZERS",
       "PESTICIDI":"PESTICIDES",
       "POLJOPRIVREDNA APOTEKA":"AGRICULTURAL PHARMACY",
       "KONTAKT":"CONTACT",
       "Kontakt":"Contact",
       "Pratite nas:":"Follow us:",
       "Kontaktirajte nas":"Contact us",
       "Budite slobodni da nas kontaktirate.":"Feel free to contact us.",
       "Odgovorićemo Vam u što kraćem roku.":"We will reply as soon as possible.",
       "Vaše ime":"Your name",
       "E-mail adresa":"E-mail address",
       "Komentar":"Comment",
       "POŠALJI PORUKU":"SEND MESSAGE",
       "NOVOSTI":"NEWS",
       "Početna":"Home",
       "Novosti":"News",
       "Novosti i obaveštenja":"News and Announcements",
       "DETALJNIJE":"READ MORE",
       "Najnovije novosti":"Latest news",
       "DPS KLAS GROUP D.O.O":"DPS KLAS GROUP D.O.O",
       "Zaposlenje":"Employment",
       "Konkurs za radno mesto":"Job vacancy",
       "Kako bi konkurisali na radno mesto u firmi DPS molimo popunite sledeći formular.":"To apply for a job at DPS please fill out the following form.",
       "Vaše prezime":"Your last name",
       "Broj telefona":"Phone number",
       "Željena pozicija":"Preferred Position",
       "Vaš CV (pdf)":"Your CV (pdf)",
       "Dodatna poruka":"Additional message",
       "KONKURIŠI":"APPLY",
       "Vaša poruka je uspešno poslata, očekujte odgovor ubrzo":"Your message was successfully sent, expect a reply soon",
       "Vaša prijava je uspešno poslata, očekujte odgovor ubrzo":"Your application has been successfully submitted, please expect a reply soon",
       "SIROVINSKI SEKTOR":"RAW MATERIAL SECTOR"
    },
    "sr":{ 
       "FIRMA OD POVERENJA":"FIRMA OD POVERENJA",
       "Sertifikati ISOQAR, UKAS i Firma od poverenja":"Sertifikati ISOQAR, UKAS i Firma od poverenja",
       "Srpski":"Srpski",
       "Engleski":"Engleski",
       "POČETNA":"POČETNA",
       "O NAMA":"O NAMA",
       "PROIZVODI":"PROIZVODI",
       "SKLADIŠTE I DISTRIBUCIJA":"SKLADIŠTE I DISTRIBUCIJA",
       "MREŽA SKLADIŠNIH CENTARA":"MREŽA SKLADIŠNIH CENTARA",
       "DISTRIBUTIVNI CENTAR BEOGRAD":"DISTRIBUTIVNI CENTAR BEOGRAD",
       "OBAVEŠTENJA":"OBAVEŠTENJA",
       "ZAPOSLENJE":"ZAPOSLENJE",
       "SIROVINSKI SEKOTR":"SIROVINSKI SEKTOR",
       "SORTNA SEMENA I HIBRIDI":"SORTNA SEMENA I HIBRIDI",
       "MINERALNA ĐUBRIVA":"MINERALNA ĐUBRIVA",
       "PESTICIDI":"PESTICIDI",
       "POLJOPRIVREDNA APOTEKA":"POLJOPRIVREDNA APOTEKA",
       "KONTAKT":"KONTAKT",
       "Kontakt":"Kontakt",
       "Pratite nas:":"Pratite nas:",
       "Kontaktirajte nas":"Kontaktirajte nas",
       "Budite slobodni da nas kontaktirate.":"Budite slobodni da nas kontaktirate.",
       "Odgovorićemo Vam u što kraćem roku.":"Odgovorićemo Vam u što kraćem roku.",
       "Vaše ime":"Vaše ime",
       "E-mail adresa":"E-mail adresa",
       "Komentar":"Komentar",
       "POŠALJI PORUKU":"POŠALJI PORUKU",
       "NOVOSTI":"NOVOSTI",
       "Početna":"Početna",
       "Novosti":"Novosti",
       "Novosti i obaveštenja":"Novosti i obaveštenja",
       "DETALJNIJE":"DETALJNIJE",
       "Najnovije novosti":"Najnovije vesti",
       "DPS KLAS GROUP D.O.O":"DPS KLAS GROUP D.O.O",
       "Zaposlenje":"Zaposlenje",
       "Konkurs za radno mesto":"Konkurs za radno mesto",
       "Kako bi konkurisali na radno mesto u firmi DPS molimo popunite sledeći formular.":"Ukoliko želite da konkurišete na radno mesto u firmi DPS KLAS GROUP D.O.O molimo popunite sledeći formular.",
       "Vaše prezime":"Vaše prezime",
       "Broj telefona":"Broj telefona",
       "Željena pozicija":"Željena pozicija",
       "Vaš CV (pdf)":"Vaš CV (pdf)",
       "Dodatna poruka":"Dodatna poruka",
       "KONKURIŠI":"KONKURIŠI",
       "Vaša poruka je uspešno poslata, očekujte odgovor ubrzo":"Vaša poruka je uspešno poslata, očekujte odgovor ubrzo",
       "Vaša prijava je uspešno poslata, očekujte odgovor ubrzo":"Vaša prijava je uspešno poslata, očekujte odgovor ubrzo",
       "SIROVINSKI SEKTOR":"SIROVINSKI SEKTOR"
    }
 }
 