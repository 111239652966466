import React from 'react';
import mapIcon from '../assets/images/map.png';


const locations = [
    '44.770835, 19.697928',
    '44.922041,19.770843',
    '44.823469,19.483935',
    '44.824538,19.454608',
    '44.867065,19.700610',
    '44.627370,19.945794',
    '44.857673,19.823613',
    '44.897819,19.864634',
    '44.930446,19.782804'
];

export default class Map extends React.Component {
    constructor(props) {
        super(props);
        this.initMap = this.initMap.bind(this);
        this.state = {};
    }

    initMap() {
        this.setState({
            _mapInit: true
        });
        var latLng = new window.google.maps.LatLng(44.770835, 19.697928
        );

        var map = new window.google.maps.Map(this.GoogleMap, {
            zoom: 10.5,
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            gestureHandling: "gestureHandling",

        });

        for (let i = 0; i < locations.length; i++) {
            var marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(locations[i].split(',')[0], locations[i].split(',')[1]),
                map: map,
                icon: mapIcon
            });
        }

    }

    componentDidMount() {
        if (this.props._googleMapsLoaded && !this.state._mapInit) {
            this.initMap();
        }

    }

    componentDidUpdate() {
        if (this.props._googleMapsLoaded && !this.state._mapInit) {
            this.initMap();
        }
    }

    render() {
        return (
            <div className="map-wrap">
                {
                    this.props._googleMapsLoaded ?
                        <div className="map" ref={(input) => { this.GoogleMap = input; }}>

                        </div>
                        : null
                }
            </div>
        );
    }
}
