import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

/*header*/

import logo from '../assets/images/logo.svg';
import logo1 from '../assets/images/logo-footer.svg';

import menu from '../assets/images/menu.svg';
import close from '../assets/images/close.svg';

import {
    Container,
    Row,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from 'reactstrap';

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }




    render() {

        return (
            <footer>
                <Container>
                    <Row>
                        <Col lg="2">
                            <Isvg src={logo1} />
                        </Col>
                        <Col lg="10">
                            <ul className="navigation">
                                <li><Link to='/'>{'POČETNA'.translate(this.props.lang)}</Link></li>
                                <li className={this.props[0].location.pathname == '/stranica/o-nama' ? 'active' : null} ><Link to='/stranica/o-nama'>{'O NAMA'.translate(this.props.lang)}</Link></li>
                                <li className={this.props[0].location.pathname == '/stranica/proizvodi' ? 'active' : null}><Link to='/stranica/proizvodi'>{'PROIZVODI'.translate(this.props.lang)}</Link></li>
                                <li className={this.props[0].location.pathname == '/stranica/mreza-skladisnih-centara' || this.props[0].location.pathname == '/stranica/distributivni-centar-beograd' ? 'active' : null}>
                                    <UncontrolledDropdown>
                                        <DropdownToggle caret>
                                            {'SKLADIŠTE I DISTRIBUCIJA'.translate(this.props.lang)}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem><Link to='/stranica/mreza-skladisnih-centara'> {'MREŽA SKLADIŠNIH CENTARA'.translate(this.props.lang)}</Link></DropdownItem>
                                            <DropdownItem><Link to='/stranica/distributivni-centar-beograd'>{'DISTRIBUTIVNI CENTAR BEOGRAD'.translate(this.props.lang)}</Link></DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </li>
                                <li className={this.props[0].location.pathname == '/novosti' ? 'active' : null}><Link to='/novosti'>{'OBAVEŠTENJA'.translate(this.props.lang)}</Link></li>

                                <li className={this.props[0].location.pathname == '/zaposlenje' ? 'active' : null}><Link to='/zaposlenje'>{'ZAPOSLENJE'.translate(this.props.lang)}</Link></li>
                                <li className={this.props[0].location.pathname == '/stranica/sortna-semena-i-hibridi' || this.props[0].location.pathname == '/stranica/mineralna-djubriva' || this.props[0].location.pathname == '/stranica/pesticidi' || this.props[0].location.pathname == '/stranica/poljoprivredna-apoteka' ? 'active' : null}>
                                    <UncontrolledDropdown>
                                        <DropdownToggle caret>
                                            {'SIROVINSKI SEKOTR'.translate(this.props.lang)}</DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem><Link to='/stranica/sortna-semena-i-hibridi'> {'SORTNA SEMENA I HIBRIDI'.translate(this.props.lang)}</Link></DropdownItem>
                                            <DropdownItem><Link to='/stranica/mineralna-djubriva'>{'MINERALNA ĐUBRIVA'.translate(this.props.lang)}</Link></DropdownItem>
                                            <DropdownItem><Link to='/stranica/pesticidi'>{'PESTICIDI'.translate(this.props.lang)}</Link></DropdownItem>
                                            <DropdownItem><Link to='/stranica/poljoprivredna-apoteka'>{'POLJOPRIVREDNA APOTEKA'.translate(this.props.lang)}</Link></DropdownItem>

                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </li>
                                <li className={this.props[0].location.pathname == '/kontakt' ? 'active' : null}><Link to='/kontakt'>{'KONTAKT'.translate(this.props.lang)}</Link></li>
                            </ul>

                        </Col>

                        <Col lg="12">
                            <div className="spacer"></div>

                        </Col>
                        <Col lg="12" className="copyright">
                            <p>Copyright © DPS Klas d.o.o. - 2019. All Rights Reserved.</p>
                            <p>Created by <a href="https://novamedia.agency" target="_blank">NOVA media</a></p>
                        </Col>
                    </Row>
                </Container>



            </footer>
        );
    }
}

export default Footer;