

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import socketIO from 'socket.io-client';

import Routes from './routes'

import './App.css';
import { langs } from './langs';
import DocumentMeta from 'react-document-meta';
var striptags = require('striptags');



if (String.prototype.translate == null) {
  String.prototype.translate = function (lang) {
    //console.log(lang);

    /*if (!localStorage.translate){
      localStorage.translate = JSON.stringify({
        'en': {
 
        },
        'sr': {
 
        }
      });
    }
 
    let obj = JSON.parse(localStorage.translate);
    obj.en[this] = this;
    obj.sr[this] = this;
 
    localStorage.translate = JSON.stringify(obj);
    
    return this;*/

    if (langs[lang] && langs[lang][this])
      return langs[lang][this];
    else return this;
  }
}


class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.handleMenu = this.handleMenu.bind(this);
    this.handleSidebar = this.handleSidebar.bind(this);

    this.setModalOpen = this.setModalOpen.bind(this);
    this.setModalInfo = this.setModalInfo.bind(this);
    this.touchEnd = this.touchEnd.bind(this);
    this.touchMove = this.touchMove.bind(this);
    this.openDeletePrompt = this.openDeletePrompt.bind(this);
    this.setUserData = this.setUserData.bind(this);
    this.generateAlias = this.generateAlias.bind(this);
    this.handleLoader = this.handleLoader.bind(this);
    this.translate = this.translate.bind(this);
    this.openSubMenu = this.openSubMenu.bind(this);
    this.setLang = this.setLang.bind(this);
    this.handlePrompt = this.handlePrompt.bind(this);

    window.googleMapsCallback = this.googleMapsCallback;

    this.state = {
      _googleMapsLoaded: false,
      uData: null,
      _menuOpen: null,
      socketIOClient: null,
      geolocation: null,
      _modalOpen: null,
      _startSwipePos: null,
      _startSwipePosY: null,
      _swipePos: null,
      _menuPos: 0,
      sidebar: true,
      deletePrompt: null,
      handleDeletePrompt: null,
      loader: null,
      subMenu: null,
      lang: 'sr',
      langs: ['ba', 'en'],
      prompt: null
    };

  }

  handlePrompt(data) {
    this.setState({
      prompt: data
    })
  }

  setLang(lang) {
    this.setState({
      lang: lang
    });
  }

  openSubMenu(name) {
    this.setState({
      subMenu: name
    });
  }

  generateAlias(str) {
    str = str.toLowerCase();
    str = str.replace(/\s\s+/g, ' ');
    str = str.replace(/ /g, '-');
    str = str.replace(/\./g, '-');
    str = str.replace(/\,/g, '-');
    str = str.replace(/š/g, 's');
    str = str.replace(/č/g, 'c');
    str = str.replace(/ć/g, 'c');
    str = str.replace(/đ/g, 'dj');
    str = str.replace(/ž/g, 'z');
    return str;
  }

  touchEnd() {
    var x = this.state._swipePos;
    if (this.state._menuOpen && this.state._startSwipePos && x - this.state._startSwipePos >= 30) {
      this.setState({
        _menuPos: 0
      });
    }

    if (this.state._menuOpen && this.state._startSwipePos && this.state._startSwipePos - x >= 30) {
      this.setState({
        _menuPos: 0,
        _menuOpen: null
      });
    }

    this.setState({
      _startSwipePos: null,
      _swipePos: null
    });

  }

  touchMove(event) {
    var x = event.touches[0].clientX;
    var y = event.touches[0].clientY;

    if (!this.state._startSwipePos) {
      this.setState({
        _startSwipePos: x,
        _startSwipePosY: y
      });
    }

    if (this.state._startSwipePos && Math.abs(x - this.state._startSwipePos) < Math.abs(y - this.state._startSwipePosY))
      return;

    if (this.state._startSwipePos < 50 && this.state._startSwipePos && x > this.state._startSwipePos + 20 && -300 + x - this.state._startSwipePos <= 0) {
      this.setState({
        _menuOpen: true,
        _menuPos: -300 + x - this.state._startSwipePos

      });
    }


    if (this.state._menuOpen && this.state._startSwipePos && -300 + x - this.state._startSwipePos <= 0) {

      this.setState({
        _menuPos: -300 + x - this.state._startSwipePos
      });
    }


    this.setState({
      _swipePos: x
    });

  }


  setUserData(data) {
    console.log(data);

    if (!data) {
      localStorage.removeItem('uData');
      this.setState({
        uData: null
      });
      return;
    }

    localStorage.setItem('uData', JSON.stringify(data));
    this.setState({
      uData: data
    });
  }


  openDeletePrompt(data, handleDeletePrompt) {
    console.log('handleDeletePrompt');
    this.setState({
      deletePrompt: data,
      handleDeletePrompt: handleDeletePrompt
    });
  }

  setModalOpen(data) {

    this.setState({
      _modalOpen: data,
    });
  }

  setModalInfo(data) {
    this.setState({
      _modalInfo: data
    });
  }



  handleMenuOpen(val) {
    var pos = 300;
    if (val) {
      pos = 0;
    }
    console.log(pos);
    this.setState({
      menuOpen: val,
      _menuPos: pos
    });
  }

  translate(text) {
    return text;
  }

  render() {
    const meta = {
      title: '„DPS KLAS GROUP“ d.o.o',
      meta: {
        charset: 'utf-8',
        name: {
          'og:url': `https://dpsklas.rs`,
          'og:title': '„DPS KLAS GROUP“ d.o.o',
          'og:image': 'https://dpsklas.rs/logo.png',
          'og:description': `Kompanija DPS KLAS GROUP je osnovana 2004. godine.  Posvećeno, vođeni vizijom uspeha i neiscrpnom ambicijom za vrhunskim kvalitetom proizvoda i zadovoljstvom kupaca, postali smo jedna od liderskih kompanija u oblasti mlinarstva i agrobiznisa.`
        }
      }
    };

    return (
      <>
        <DocumentMeta {...meta} />
        <Routes
          {...this.state}
          handleMenu={this.handleMenu}
          handleSidebar={this.handleSidebar}
          setUserData={this.setUserData}
          openDeletePrompt={this.openDeletePrompt}

          setModalOpen={this.setModalOpen}
          setModalInfo={this.setModalInfo}
          touchEnd={this.touchEnd}
          touchMove={this.touchMove}
          generateAlias={this.generateAlias}
          handleLoader={this.handleLoader}
          translate={this.translate}
          openSubMenu={this.openSubMenu}
          setLang={this.setLang}
          handlePrompt={this.handlePrompt}
        />
      </>

    );

  }

  handleLoader(state) {
    this.setState({
      loader: state
    });
  }


  handleMenu(state) {


    this.setState({
      _menuOpen: state
    });

  }

  handleSidebar(state) {
    if (this.state.subMenu) {
      this.setState({
        subMenu: null
      });

    } else {
      this.setState({
        sidebar: state
      });
    }
  }

  componentDidMount() {
    let socket = socketIO('https://socket.autoin.ba');
    this.setState({
      socketIOClient: socket
    });


    if (localStorage.uData) {
      socket.emit("adminVerify", JSON.parse(localStorage.uData));

      socket.on("adminVerify", (data) => {
        if (data.successful) {
          this.setUserData(data.user);
        }
      });
    }

    socket.on("reconnect", (data) => {
      if (localStorage.uData) {
        socket.emit("adminVerify", JSON.parse(localStorage.uData));
      }
    });

    if (window.location.href.indexOf('?lang=') != -1) {
      let lang = window.location.href.split('?lang=').pop();
      this.setLang(lang);
    }

  }



  googleMapsCallback() {
    console.log("true");
    this.setState({ _googleMapsLoaded: true });
  }

}

export default App;
