import React, { Component } from 'react';
import {
    Router,
    Route,
    Switch,
     
} from 'react-router-dom';
import createHistory from 'history/createBrowserHistory'



import { GoogleMapScript } from './components/googleMapScript';
import HomePage from './views/homePage';
import NewsPage from './views/newsPage';
import NewsDetailPage from './views/newsDetailPage';
import ContactPage from './views/contactPage';
import CompetitionPage from './views/competitionPage';
import EditPage from './views/editPage';
import Page from './views/page';
const history = createHistory()

class Routes extends Component {

    componentDidMount() {
        history.listen((location, action) => {
            //console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
            //console.log(`The last navigation action was ${action}`)
            //console.log(location.search);
            if (location.search.indexOf('?lang') == -1){
                history.push(location.pathname + '?lang='+this.props.lang)
            }
       })
       
    }
  
    render() {
        return (
            <Router history={history}>
                <div>

                    <GoogleMapScript API_KEY="AIzaSyDx7uNRz2GYWKLlAlfT6wugFOSBXQ7EZaQ" />

                    <Switch className="react-switch">
                        <Route
                            path="/"
                            exact
                            render={(...renderProps) => (
                                <HomePage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/stranica/:alias"
                            exact
                            render={(...renderProps) => (
                                <Page {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/editPage/:id/:token/:lang"
                            exact
                            render={(...renderProps) => (
                                <EditPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/novosti"
                            exact
                            render={(...renderProps) => (
                                <NewsPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/novosti/:id"
                            exact
                            render={(...renderProps) => (
                                <NewsDetailPage {...renderProps} {...this.props} />
                            )}
                        />
                        <Route
                            path="/kontakt"
                            exact
                            render={(...renderProps) => (
                                <ContactPage {...renderProps} {...this.props} />
                            )}
                        />

                        <Route
                            path="/zaposlenje"
                            exact
                            render={(...renderProps) => (
                                <CompetitionPage {...renderProps} {...this.props} />
                            )}
                        />




                    </Switch>
                </div>
            </Router >
        );
    }
}

export default Routes;